<template>
    <div>
        <div class="content">
            <el-switch v-model="isPowerOn" active-text="开" inactive-text="关"/>
        </div>
        <div style="text-align: center">
            <el-button @click="powerOnOffSubmit" :disabled="disableBtn" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>

    import loading from '@/utils/cmdLoading';
    import tool from '@/utils/tool.js';
    import message from '@/utils/message';
    import mqttService from '@/api/mqtt-service';
    import Global from '@/components/Global';

    export default {

        name: 'power-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                // true-开机，false-关机
                isPowerOn: false,
                disableBtn: false,
                command: {

                    // 读指令
                    readCommandCode: 'queryPowerStatus',
                    // 根据开关标记isPowerOn：true-modifyPowerOn，false-modifyPowerOff
                    modifyCommandCode: ''
                },
                // 请求参数
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('开关机状态读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            },
            powerOnOffSubmit() {

                this.disableBtn = true;
                this.requestParam = {isPowerOn: this.isPowerOn};
                this.command.modifyCommandCode = this.isPowerOn ? 'modifyPowerOn' : 'modifyPowerOff';
                this.cmdLoading = this.openCmdLoading('状态配置中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.modifyCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        let resultData = that.successCallback(res.data);
                        if (tool.isObjectValueEqual(that.requestParam, resultData)) {

                            message.success('配置成功');
                        } else {

                            message.error('配置失败');
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            successCallback(data) {

                this.disableBtn = false;
                this.isPowerOn = data === '1';
                return {

                    isPowerOn: this.isPowerOn
                };
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .content {

        height: 490px;
        margin: 10px 0 1% 5%
    }
</style>
